import React, { useState } from "react"
import Bg from "../images/od_logo_hollow.png"

import Layout from "../components/layout"

const IndexPage = () => {
  const [Email, setEmail] = useState({
    email: "",
  })

  const handleChange = e => {
    setEmail({
      ...Email,
      [e.target.name]: e.target.value,
    })
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "newsletter", ...Email }),
    })
      .then(() => window.open("../ThankYou", "_self"))
      .catch(() => window.open("../Error", "_self"))

    e.preventDefault()
  }

  console.log(Email)
  return (
    <Layout>
      <div class="relative bg-yellow overflow-hidden">
        <div class="relative pt-12 pb-16 sm:pb-24 lg:pb-24">
          <main class="mx-auto max-w-7xl px-4 ">
            <img class="w-1/5 mx-auto" src={Bg} alt="" />
            <div class="text-center">
              <h1 class="text-4xl tracking-tight font-extrabold text-red sm:text-5xl md:text-7xl">
                Your top source for authentic Japanese decorations & supplies.
              </h1>

              <div class="animate-bounce mt-15">
                <div class="text-red">
                  <span class="sr-only">scroll</span>
                  <svg
                    class="h-15 w-15 mx-auto"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-18.005-1.568l1.415-1.414 4.59 4.574 4.579-4.574 1.416 1.414-5.995 5.988-6.005-5.988z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <div class="bg-white">
        <div class="pt-12 sm:pt-16 lg:pt-24">
          <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
            <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
              <h2 class="text-xl font-semibold text-black uppercase tracking-wider">
                25 piece
              </h2>
              <p class="text-3xl font-extrabold text-black sm:text-4xl lg:text-5xl">
                Decorative Origami Paper Cranes
              </p>
              <p class="text-xl text-black">
                Made from authentic imported traditional Washi paper to ensure
                quality and strength of the birds.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-8 pb-12 bg-red sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div class="relative">
            <div class="absolute inset-0 h-1/2 bg-white"></div>
            <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div class="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-3 lg:gap-4 lg:space-y-0">
                <div class=" flex flex-col overflow-hidden">
                  <div class="px-6 py-8 bg-yellow sm:p-10 sm:pb-1">
                    <div class="flex justify-between">
                      <h3
                        class=" px-4 pt-3 rounded-full text-sm font-semibold tracking-wide uppercase bg-red text-white"
                        id="tier-standard"
                      >
                        Red
                      </h3>
                      <h3
                        class="px-4 py-2 rounded-full text-xl font-semibold tracking-wide uppercase bg-black text-yellow"
                        id="tier-standard"
                      >
                        $20.00
                      </h3>
                    </div>
                    <img
                      class=" w-full"
                      src="https://images-na.ssl-images-amazon.com/images/I/816NQRiafIL._AC_SL1500_.jpg"
                      alt=""
                    />
                  </div>
                  <div class="flex-1 flex flex-col px-6 pt-6 bg-white sm:p-10 ">
                    <div class="rounded-md shadow">
                      <a
                        href="https://www.amazon.com/dp/B08NXSNQDD"
                        class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium  text-white bg-red hover:bg-black"
                        aria-describedby="tier-standard"
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col overflow-hidden">
                  <div class="px-6 py-8 bg-yellow sm:p-10 sm:pb-1">
                    <div class="flex justify-between">
                      <h3
                        class=" px-4 pt-3 rounded-full text-sm font-semibold tracking-wide uppercase bg-red text-white"
                        id="tier-standard"
                      >
                        Blue
                      </h3>
                      <h3
                        class="px-4 py-2 rounded-full text-xl font-semibold tracking-wide uppercase bg-black text-yellow"
                        id="tier-standard"
                      >
                        $20.00
                      </h3>
                    </div>
                    <img
                      class=" w-full"
                      src="https://images-na.ssl-images-amazon.com/images/I/81ST-WlfEKL._AC_SL1500_.jpg"
                      alt=""
                    />
                  </div>
                  <div class="flex-1 flex flex-col px-6 pt-6 bg-white sm:p-10 ">
                    <div class="rounded-md shadow">
                      <a
                        href="https://www.amazon.com/dp/B08NXVN97Y"
                        class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium  text-white bg-red hover:bg-black"
                        aria-describedby="tier-standard"
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col overflow-hidden">
                  <div class="px-6 py-8 bg-yellow sm:p-10 sm:pb-1">
                    <div class="flex justify-between">
                      <h3
                        class=" px-4 pt-3 rounded-full text-sm font-semibold tracking-wide uppercase bg-red text-white"
                        id="tier-standard"
                      >
                        Multi
                      </h3>
                      <h3
                        class="px-4 py-2 rounded-full text-xl font-semibold tracking-wide uppercase bg-black text-yellow"
                        id="tier-standard"
                      >
                        $20.00
                      </h3>
                    </div>
                    <img
                      class=" w-full"
                      src="https://images-na.ssl-images-amazon.com/images/I/91Mjsti7mvL._AC_SL1500_.jpg"
                      alt=""
                    />
                  </div>
                  <div class="flex-1 flex flex-col px-6 pt-6 bg-white sm:p-10 ">
                    <div class="rounded-md shadow">
                      <a
                        href="https://www.amazon.com/dp/B08NXSJW1Z"
                        class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium  text-white bg-red hover:bg-black"
                        aria-describedby="tier-standard"
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <p class="max-w-3xl mx-auto text-center mt-20 text-2xl font-extrabold text-yellow sm:text-4xl lg:text-3xl">
                There was an error with your last submission, please try again
              </p>
              <div class="max-w-md mx-auto">
                <form
                  method="POST"
                  target="_self"
                  id="newsletter"
                  name="newsletter"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                  class="mt-8 sm:flex"
                >
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    autocomplete="email"
                    required
                    class="w-full px-5 py-3 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs border-gray-300"
                    placeholder="Enter your email"
                  />
                  <div class="mt-3 shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    <button
                      type="submit"
                      class="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white bg-black hover:bg-yellow hover:text-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                    >
                      Notify me
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
